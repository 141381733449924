<template>
  <div class="partition-form van-common-detail-button">
    <van-form ref="appForm" :input-align="constants.inputAlign" label-width="110px">
      <div class="area-title">
        <p class="title">
          货物信息
        </p>
      </div>
      <van-cell title="提货申请编号：" :value="utils.isEffectiveCommon(appForm.applyNo)" />
      <van-cell title="仓库名称：" :value="utils.isEffectiveCommon(appForm.warehouseName)" />
      <van-cell title="提货类型：" value="整柜提货" />
      <van-field v-model="appForm.applyDate" label="申请提货日期：" placeholder="请选择日期" required :rules="[{ required: true }]" @click="showApplyDate = true" />
      <van-popup v-model="showApplyDate" round position="bottom">
        <van-datetime-picker v-model="currentDate" type="date" @confirm="date => confirmDate(date)" @cancel="showApplyDate = false" />
      </van-popup>
      <van-cell title="柜号：" :value="utils.isEffectiveCommon(appForm.cabinetNo)" />
      <van-cell title="货物名称：" :value="utils.isEffectiveCommon(appForm.cargoName)" />
      <van-cell title="总金额（USD）：" :value="utils.isEffectiveCommon(appForm.money)" />
      <van-cell title="可提件数：" :value="utils.isEffectiveCommon(appForm.extractableNumber)" />
      <van-cell title="可提重量（KG）：" :value="utils.isEffectiveCommon(appForm.extractableWeight)" />
      <van-cell title="实际件数：" :value="utils.isEffectiveCommon(appForm.actualPieceNumber)" />
      <van-cell title="实际重量（KG）：" :value="utils.isEffectiveCommon(appForm.actualWeight)" />
      <div class="area-title">
        <p class="title">
          装车信息
        </p>
      </div>
      <van-field v-model="appForm.vehiclesInfoVo.contacts" label="司机姓名：" placeholder="请输入司机姓名" :maxlength="20" />
      <van-field v-model="appForm.vehiclesInfoVo.phone" label="电话：" placeholder="请输入电话" :maxlength="20" />
      <van-field v-model="appForm.vehiclesInfoVo.certificates" label="身份证号：" placeholder="请输入身份证号" :maxlength="20" />
      <van-field v-model="appForm.vehiclesInfoVo.numberPlates" label="车牌号：" placeholder="请输入车牌号" :maxlength="20" />
      <van-field v-model="appForm.vehiclesInfoVo.remarks" label="备注：" placeholder="请输入备注" :maxlength="200" />
      <div class="button-fixed">
        <van-button type="info" class="button-fixed-margin" block @click="submit">
          提交
        </van-button>
        <van-button type="info" block @click="$router.back()">
          返回
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import moment from 'moment'
import { List, PullRefresh, Cell, CellGroup, Search, Button, Row, Col, Form, Field, Popup, Picker, Uploader, SwipeCell, Toast, Calendar, Checkbox, CheckboxGroup, RadioGroup, Radio, DatetimePicker } from 'vant'

export default {
  components: {
    [DatetimePicker.name]: DatetimePicker,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [CheckboxGroup.name]: CheckboxGroup,
    [Checkbox.name]: Checkbox,
    [Calendar.name]: Calendar,
    [Toast.name]: Toast,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Search.name]: Search,
    [Button.name]: Button,
    [Row.name]: Row,
    [Col.name]: Col,
    [Form.name]: Form,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Uploader.name]: Uploader,
    [SwipeCell.name]: SwipeCell
  },
  data () {
    return {
      stockContainerId: this.$route.query.stockContainerId,
      showApplyDate: false,
      currentDate: new Date(),
      accountForm: {},
      showGoods: false,
      detailList: [],
      appForm: {
        applyNo: '',
        applyDate: '',
        cabinetNo: '',
        cargoName: '',
        money: '',
        extractableNumber: '',
        warehouseName: '',
        extractableWeight: '',
        actualPieceNumber: '',
        actualWeight: '',
        vehiclesInfoVo: {},
        containerInfoVo: {}
      },
      finishedText: '没有更多了',
      page: 1,
      tableList: [],
      loading: false,
      finished: false,
      refreshing: false,
      loadingSubmit: false
    }
  },
  created () {
    this.getDetail()
  },
  methods: {
    // 获取详情
    getDetail () {
      this.api.delivery.packOutCabinetInfoDetail(this.stockContainerId).then(result => {
        const resData = result.data.value
        this.appForm = this._.assign(this.appForm, resData)
      }).catch((e) => {
      })
    },
    confirmDate (val) {
      this.appForm.applyDate = `${moment(val).format('YYYY-MM-DD')}`
      this.showApplyDate = false
    },
    // 提交
    submit () {
      this.$refs.appForm.validate().then(() => {
        const data = this._.cloneDeep(this.appForm)
        data.outType = 1
        data.applyDate = `${data.applyDate} 00:00:00`
        data.containerInfoVo = this._.cloneDeep(data)
        this.api.delivery.packOutAdd(data).then(result => {
          Toast.success(result.data.message || '提交成功')
          this.$router.back()
        }).finally(() => {
        })
      })
    }
  }
}
</script>

<style>
</style>
