var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "partition-form van-common-detail-button" },
    [
      _c(
        "van-form",
        {
          ref: "appForm",
          attrs: {
            "input-align": _vm.constants.inputAlign,
            "label-width": "110px"
          }
        },
        [
          _c("div", { staticClass: "area-title" }, [
            _c("p", { staticClass: "title" }, [_vm._v(" 货物信息 ")])
          ]),
          _c("van-cell", {
            attrs: {
              title: "提货申请编号：",
              value: _vm.utils.isEffectiveCommon(_vm.appForm.applyNo)
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "仓库名称：",
              value: _vm.utils.isEffectiveCommon(_vm.appForm.warehouseName)
            }
          }),
          _c("van-cell", { attrs: { title: "提货类型：", value: "整柜提货" } }),
          _c("van-field", {
            attrs: {
              label: "申请提货日期：",
              placeholder: "请选择日期",
              required: "",
              rules: [{ required: true }]
            },
            on: {
              click: function($event) {
                _vm.showApplyDate = true
              }
            },
            model: {
              value: _vm.appForm.applyDate,
              callback: function($$v) {
                _vm.$set(_vm.appForm, "applyDate", $$v)
              },
              expression: "appForm.applyDate"
            }
          }),
          _c(
            "van-popup",
            {
              attrs: { round: "", position: "bottom" },
              model: {
                value: _vm.showApplyDate,
                callback: function($$v) {
                  _vm.showApplyDate = $$v
                },
                expression: "showApplyDate"
              }
            },
            [
              _c("van-datetime-picker", {
                attrs: { type: "date" },
                on: {
                  confirm: function(date) {
                    return _vm.confirmDate(date)
                  },
                  cancel: function($event) {
                    _vm.showApplyDate = false
                  }
                },
                model: {
                  value: _vm.currentDate,
                  callback: function($$v) {
                    _vm.currentDate = $$v
                  },
                  expression: "currentDate"
                }
              })
            ],
            1
          ),
          _c("van-cell", {
            attrs: {
              title: "柜号：",
              value: _vm.utils.isEffectiveCommon(_vm.appForm.cabinetNo)
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "货物名称：",
              value: _vm.utils.isEffectiveCommon(_vm.appForm.cargoName)
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "总金额（USD）：",
              value: _vm.utils.isEffectiveCommon(_vm.appForm.money)
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "可提件数：",
              value: _vm.utils.isEffectiveCommon(_vm.appForm.extractableNumber)
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "可提重量（KG）：",
              value: _vm.utils.isEffectiveCommon(_vm.appForm.extractableWeight)
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "实际件数：",
              value: _vm.utils.isEffectiveCommon(_vm.appForm.actualPieceNumber)
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "实际重量（KG）：",
              value: _vm.utils.isEffectiveCommon(_vm.appForm.actualWeight)
            }
          }),
          _c("div", { staticClass: "area-title" }, [
            _c("p", { staticClass: "title" }, [_vm._v(" 装车信息 ")])
          ]),
          _c("van-field", {
            attrs: {
              label: "司机姓名：",
              placeholder: "请输入司机姓名",
              maxlength: 20
            },
            model: {
              value: _vm.appForm.vehiclesInfoVo.contacts,
              callback: function($$v) {
                _vm.$set(_vm.appForm.vehiclesInfoVo, "contacts", $$v)
              },
              expression: "appForm.vehiclesInfoVo.contacts"
            }
          }),
          _c("van-field", {
            attrs: {
              label: "电话：",
              placeholder: "请输入电话",
              maxlength: 20
            },
            model: {
              value: _vm.appForm.vehiclesInfoVo.phone,
              callback: function($$v) {
                _vm.$set(_vm.appForm.vehiclesInfoVo, "phone", $$v)
              },
              expression: "appForm.vehiclesInfoVo.phone"
            }
          }),
          _c("van-field", {
            attrs: {
              label: "身份证号：",
              placeholder: "请输入身份证号",
              maxlength: 20
            },
            model: {
              value: _vm.appForm.vehiclesInfoVo.certificates,
              callback: function($$v) {
                _vm.$set(_vm.appForm.vehiclesInfoVo, "certificates", $$v)
              },
              expression: "appForm.vehiclesInfoVo.certificates"
            }
          }),
          _c("van-field", {
            attrs: {
              label: "车牌号：",
              placeholder: "请输入车牌号",
              maxlength: 20
            },
            model: {
              value: _vm.appForm.vehiclesInfoVo.numberPlates,
              callback: function($$v) {
                _vm.$set(_vm.appForm.vehiclesInfoVo, "numberPlates", $$v)
              },
              expression: "appForm.vehiclesInfoVo.numberPlates"
            }
          }),
          _c("van-field", {
            attrs: {
              label: "备注：",
              placeholder: "请输入备注",
              maxlength: 200
            },
            model: {
              value: _vm.appForm.vehiclesInfoVo.remarks,
              callback: function($$v) {
                _vm.$set(_vm.appForm.vehiclesInfoVo, "remarks", $$v)
              },
              expression: "appForm.vehiclesInfoVo.remarks"
            }
          }),
          _c(
            "div",
            { staticClass: "button-fixed" },
            [
              _c(
                "van-button",
                {
                  staticClass: "button-fixed-margin",
                  attrs: { type: "info", block: "" },
                  on: { click: _vm.submit }
                },
                [_vm._v(" 提交 ")]
              ),
              _c(
                "van-button",
                {
                  attrs: { type: "info", block: "" },
                  on: {
                    click: function($event) {
                      return _vm.$router.back()
                    }
                  }
                },
                [_vm._v(" 返回 ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }